import React from "react";
import TeamMemberItem from "./teamMemberItem";
import { List } from "components/layout";

/* Team Member List
============================================================================= */

const TeamMemberList = ({ team }) => {
	// Config

	// Render Team Member
	const renderTeamMembers = () => {
		return team.map((teamMember, i) => {
			return (
				<TeamMemberItem teamMember={teamMember} key={teamMember.name} />
			);
		});
	};

	// Render
	return (
		<List
			title="Our Team"
			grid="grid-cols-1 gap-12"
			className="w-full padding-bottom"
		>
			{renderTeamMembers()}
		</List>
	);
};

/* Export
============================================================================= */

export default TeamMemberList;
