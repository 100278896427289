import React, { useEffect } from "react";
import Image from "gatsby-image";
import { Link, graphql } from "gatsby";
import { SEO } from "components";
import BlockContent from "@sanity/block-content-to-react";
import { TeamMemberList, HostList } from "components/about";
import { kebabCase, getGatsbyImage } from "helpers";
import { PageHeading } from "components/layout";
import { SectionHeading } from "components/layout";
import useMenu from "state/useMenu";

/* About Page
============================================================================= */

const AboutPage = ({ data }) => {
	// Config
	const { setAd } = useMenu();

	useEffect(() => {
		setAd();
	}, []);

	return (
		<>
			<SEO title="About Litmus Media" />

			<section
				role="region"
				aria-label="About Litmus Media"
				className="w-full padding-top"
			>
				<Image
					fluid={getGatsbyImage(data.sanityAbout.about_image)}
					className="w-full h-96"
					imgStyle={{
						objectFit: "cover",
						objectPosition: "50% 50%",
					}}
				/>
				<div className="w-full mx-auto horizontal-padding padding-top padding-bottom">
					<BlockContent
						blocks={data.sanityAbout._rawAboutContent}
						className="prose-xl block-content"
						renderContainerOnSingleChild
					/>
				</div>
			</section>
			<TeamMemberList team={data.sanitySiteConfig.team_member_list} />
		</>
	);

	// Render
};

/* Page Query
============================================================================= */

export const pageQuery = graphql`
	query AboutPageQuery {
		sanityAbout {
			_rawAboutContent
			about_image {
				image {
					asset {
						localFile {
							childImageSharp {
								fluid(maxWidth: 1024) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
		sanitySiteConfig {
			team_member_list {
				name
				role
				description
				image {
					image {
						asset {
							localFile {
								childImageSharp {
									fluid(maxWidth: 1024) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

/* Export
============================================================================= */

export default AboutPage;
