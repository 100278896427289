import React from "react";
import { Link } from "gatsby";
import Image from "gatsby-image";
import { kebabCase, getGatsbyImage } from "helpers";

/* Team Member Item
============================================================================= */

const TeamMemberItem = ({ teamMember }) => {
	// Config

	// Render
	return (
		<li className="w-full flex md:flex-row flex-col">
			<div className="md:w-1/4 w-full md:pr-8 md:pb-0 pb-4 rounded-md overflow-hidden">
				<Image
					fluid={getGatsbyImage(teamMember.image)}
					alt={teamMember.image.alt}
					className="w-full shadow-lg rounded-md"
					placeholderStyle={{
						filter: "blur(50px)",
					}}
					placeholderStyle={{
						filter: "blur(50px)",
					}}
					imgStyle={{
						objectFit: "cover",
						objectPosition: "50% 50%",
					}}
				/>
			</div>
			<div className="md:w-3/4 w-full">
				<h4 className="font-body text-2xl font-bold tracking-wide pb-1">
					{teamMember.name}
				</h4>
				<h5 className="font-body text-base pb-4 uppercase tracking-wider text-gray-900">
					{teamMember.role}
				</h5>
				<p className="font-body leading-relaxed">
					{teamMember.description}
				</p>
			</div>
		</li>
	);
};

/* Export
============================================================================= */

export default TeamMemberItem;
